import React, { SVGProps, useMemo } from 'react';
import cx from 'classnames';
import styles from './Icon.module.css';

const SVG_SPRITE_URL = '/sprite.svg';

export interface IconProps extends SVGProps<SVGSVGElement> {
  name: string;
  title?: string;
  stroked?: boolean;
  filled?: boolean;
  className?: string;
}

export const Icon: React.FC<IconProps> = ({
  name,
  title = null,
  stroked = false,
  filled = true,
  className,
  ...rest
}) => {
  const classNames = useMemo(
    () =>
      cx(
        styles.icon,
        { [styles.stroked]: stroked, [styles.filled]: filled },
        className,
      ),
    [className, stroked, filled],
  );
  const link = useMemo(() => `${SVG_SPRITE_URL}#${name}`, [name]);

  return (
    <svg className={classNames} {...rest}>
      {title && <title>{title}</title>}
      <use xlinkHref={link} xmlnsXlink="http://www.w3.org/1999/xlink" />
    </svg>
  );
};
