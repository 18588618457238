import { RefObject, useCallback, useEffect } from 'react';

export type EventType = MouseEvent | TouchEvent;
export type Handler = (event: EventType) => void;
export type EventHandlersConfig = {
  mouse: 'mousedown' | 'mouseup';
  touch: 'touchstart' | 'touchend';
  excludes?: RefObject<HTMLElement>[];
};

export default function useOutsideClick<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: Handler,
  config: EventHandlersConfig = { mouse: 'mousedown', touch: 'touchstart' },
) {
  const listener = useCallback(
    (event: EventType) => {
      if (
        !ref.current ||
        ref.current.contains(event.target as Node) ||
        config.excludes?.find(({ current: node }) =>
          node?.contains(event.target as Node),
        )
      ) {
        return;
      }

      handler(event);
    },
    [ref, config, handler],
  );

  useEffect(() => {
    document.addEventListener(config.mouse, listener);
    document.addEventListener(config.touch, listener);

    return () => {
      document.removeEventListener(config.mouse, listener);
      document.removeEventListener(config.touch, listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, listener, handler]);
}
