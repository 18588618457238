import { BaseFetcher, FetcherParams, FetcherQueryParams } from '../fetcher';

export abstract class ApiControllerBase {
  protected client: BaseFetcher;
  protected controllerName: string;

  protected constructor(client: BaseFetcher, controllerName: string) {
    this.client = client;
    this.controllerName = controllerName;
  }

  public getPath(url: string, params?: FetcherQueryParams): string {
    return this.client.createUri(this.url(url), params).toString();
  }

  protected url(next: string = ''): string {
    const nextRoute = next !== '' ? `/${next}` : '';
    return `/api/${this.controllerName}${nextRoute}`;
  }

  protected process<T = any>(request: Promise<T>) {
    return request;
  }

  protected get<T = any>(url: string, config?: FetcherParams) {
    return this.process(this.client.get<T>(this.url(url), { ...config }));
  }

  protected post<T = any, D = any>(url: string, config?: FetcherParams<D>) {
    return this.process(this.client.post<T, D>(this.url(url), { ...config }));
  }

  protected put<T = any, D = any>(url: string, config?: FetcherParams<D>) {
    return this.process(this.client.put<T, D>(this.url(url), { ...config }));
  }

  protected patch<T = any, D = any>(url: string, config?: FetcherParams<D>) {
    return this.process(this.client.patch<T, D>(this.url(url), { ...config }));
  }

  protected delete<T = any, D = any>(url: string, config?: FetcherParams<D>) {
    return this.process(this.client.delete<T, D>(this.url(url), { ...config }));
  }
}
