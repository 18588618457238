import React from 'react';
import { Nullable } from 'types/common';
import { Platform, PlatformKey } from 'types/api/platform';
import { PlatformChip } from '../../components/PlatformChip';

export const platforms: Platform[] = [
  {
    name: 'Bitrix',
    icon: '/images/bitrix.png',
    key: PlatformKey.Bitrix,
    isAvailable: true,
  },
  {
    name: 'Yii2',
    icon: '/images/yii2.png',
    key: PlatformKey.Yii2,
    isAvailable: true,
  },
  {
    name: 'Go',
    icon: '/images/go.png',
    key: PlatformKey.Go,
    isAvailable: false,
  },
  {
    name: 'PHP',
    icon: '/images/php.png',
    key: PlatformKey.PHP,
    isAvailable: true,
  },
  {
    name: 'Python',
    icon: '/images/python.png',
    key: PlatformKey.Python,
    isAvailable: false,
  },
  {
    name: 'Simfony',
    icon: '/images/simfony.png',
    key: PlatformKey.Symfony,
    isAvailable: true,
  },
];

export interface PlatformSelectProps {
  bucketId: Nullable<string>;
  onSelectPlatform: (platform: Platform) => void;
}

export const PlatformSelect: React.FC<PlatformSelectProps> = ({
  bucketId,
  onSelectPlatform,
}) => {
  return (
    <div data-bucket-id={bucketId} className="w-full h-full">
      <div className="flex flex-wrap gap-2">
        {platforms.map((platform) => (
          <PlatformChip
            key={platform.key}
            name={platform.name}
            icon={platform.icon}
            onClick={() => onSelectPlatform(platform)}
          />
        ))}
      </div>
    </div>
  );
};
