import React, { useMemo } from 'react';
import { Transition } from '@headlessui/react';
import cx from 'classnames';
import { Icon } from 'components/Icon';
import styles from './Checkbox.checker.module.css';

export interface CheckboxCheckerProps {
  checked: boolean;
}

export const CheckboxChecker: React.FC<CheckboxCheckerProps> = ({
  checked,
}) => {
  const classNames = useMemo(
    () => cx(styles.checker, { [styles.checked]: checked }),
    [checked],
  );

  return (
    <div className={classNames}>
      <Transition
        show={checked}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className={styles.checked_icon}
      >
        <Icon name="checked" stroked></Icon>
      </Transition>
    </div>
  );
};
