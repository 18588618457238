import { PagingBucketLogs } from 'types/api';
import { ApiControllerBase } from '../base/api-controller-base';
import { BaseFetcher, FetcherQueryParams } from '../fetcher';

export interface ReadQueryParams {
  limit?: number;
  offet?: number;
  sort?: string;
}

export class ReadController extends ApiControllerBase {
  constructor(client: BaseFetcher) {
    super(client, 'read');
  }

  public readLogs(
    id: string,
    params?: ReadQueryParams & FetcherQueryParams,
  ): Promise<PagingBucketLogs> {
    return this.process(this.get(id.toString(), { params }));
  }
}
