import React, { useMemo } from 'react';
import cx from 'classnames';
import { Loader } from 'components/Loader';
import { WithChildren } from 'types/common';
import { ButtonColor, ButtonShape, ButtonSize, ButtonTheme } from './types';
import styles from './Button.module.css';
import { Transition } from '@headlessui/react';

export interface ButtonProps extends WithChildren {
  theme?: ButtonTheme;
  color?: ButtonColor;
  size?: ButtonSize;
  shape?: ButtonShape;
  disabled?: boolean;
  loading?: boolean;
  wide?: boolean;
  onClick?: () => void;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  theme = 'default',
  color = 'dark',
  size = 'md',
  shape = 'rectangle',
  disabled = false,
  loading = false,
  wide = false,
  onClick,
}) => {
  const classNames = useMemo(
    () =>
      cx(
        styles.button,
        styles?.[theme],
        styles?.[color],
        styles?.[size],
        styles?.[shape],
        {
          [styles.disabled]: disabled,
          [styles.loading]: loading,
          [styles.wide]: wide,
        },
      ),
    [theme, color, size, shape, disabled, loading, wide],
  );

  return (
    <button className={classNames} disabled={disabled} onClick={onClick}>
      <div className={styles.inner}>
        <span className={styles.text}>{children}</span>

        <Transition
          show={loading}
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className={styles.loader}
        >
          <Loader />
        </Transition>
      </div>
    </button>
  );
};
