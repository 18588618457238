export enum PlatformKey {
  Bitrix = 'bitrix',
  Yii2 = 'yii2',
  Go = 'go',
  PHP = 'php',
  Python = 'python',
  Symfony = 'symfony',
}

export interface Platform {
  name: string;
  icon: string;
  key: PlatformKey;
  isAvailable: boolean;
}
