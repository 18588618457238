import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import ym from '@appigram/react-yandex-metrika';
import { api } from 'services/api';
import useMedia from 'utils/hooks/use-media';
import { LogModel } from 'types/api';
import { Nullable } from 'types/common';
import { SlidingPanel } from 'components/SlidingPanel';
import { Loader } from 'components/Loader';
import { LogsList } from './modules/LogsList/LogsList';
import { Snippet } from './modules/Snippet/Snippet';
import { LogModal } from './modules/LogModal';

const LIMIT = 20;

export const Bucket = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const [selectedLog, setSelectedLog] = useState<Nullable<LogModel>>(null);
  const isTablet = useMedia('(max-width: 1024px)');
  const queryClient = useQueryClient();

  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    'logs',
    ({ pageParam: offset = 0 }) =>
      api.read.readLogs(id?.toString() || '', {
        limit: LIMIT,
        offset,
      }),
    {
      getNextPageParam: ({ pagination }) =>
        pagination.total > Number(pagination.offset) + Number(pagination.limit)
          ? Number(pagination.offset) + LIMIT
          : undefined,
    },
  );

  const logs = useMemo(
    () =>
      data?.pages?.reduce(
        (rows: LogModel[], pages) => [...rows, ...pages.rows],
        [],
      ),
    [data],
  );

  const closeLogPanel = () => setSelectedLog(null);

  const handleLogClick = (log: LogModel) => setSelectedLog(log);

  const handleLoadMore = () => {
    ym('reachGoal', 'logs_load_more');
    fetchNextPage();
  };

  const handleUpdate = () =>
    requestAnimationFrame(() =>
      queryClient.resetQueries({ queryKey: ['logs'] }),
    );

  return (
    <SlidingPanel
      isOpen={Boolean(selectedLog)}
      clipContent={!isTablet}
      size={isTablet ? '100%' : '50%'}
      onClose={closeLogPanel}
      panel={<LogModal log={selectedLog} onClose={closeLogPanel} />}
    >
      <div className="w-full h-full flex flex-col py-4 bg-[color:var(--alabaster)]">
        {isFetching && !isFetchingNextPage && (
          <div className="h-full py-10 flex justify-center">
            <Loader color="blue" className="text-4xl" />
          </div>
        )}

        {!!error && (
          <div className="w-full h-full py-10 flex justify-center">
            <span className="text-xl text-center whitespace-pre-line">
              {t('bucket.getBucketError')}
            </span>
          </div>
        )}

        {logs && logs.length <= 0 && (
          <Snippet
            bucketId={id ?? null}
            limit={LIMIT}
            onUpdate={handleUpdate}
          />
        )}

        {logs && logs.length > 0 && (
          <LogsList
            limit={LIMIT}
            logs={logs}
            activeLog={selectedLog}
            isCanLoadMore={hasNextPage ?? false}
            isLoadingMore={isFetchingNextPage}
            onLogClick={handleLogClick}
            onLoadMore={handleLoadMore}
            onUpdate={handleUpdate}
          />
        )}
      </div>
    </SlidingPanel>
  );
};
