import { Collapser } from 'components/Collapser';

export const Collapsers = () => {
  return (
    <div className="w-full flex gap-4">
      <div>
        <Collapser initHeight={120}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque
          veritatis, amet modi dolor optio ab maxime, rerum eius voluptatem enim
          id? Ipsam itaque veniam tempora a quasi rem voluptates. Voluptates
          laudantium consectetur fuga cupiditate repellat nesciunt cumque? Nisi
          incidunt doloribus cum quasi labore velit facere vitae alias corrupti
          blanditiis quas quo, earum nam nihil saepe vero sapiente expedita
          quidem iure veniam fuga quam aperiam. Excepturi a inventore optio
          eligendi error, animi suscipit quas iure alias repellendus in saepe.
          Illum exercitationem velit culpa nemo, possimus recusandae? Qui
          tempore sint eius debitis. Magnam adipisci nulla asperiores voluptatem
          veritatis mollitia enim doloremque similique quis eligendi! Labore
          totam consequatur exercitationem aspernatur! Tempora nesciunt
          temporibus ut non perspiciatis, veritatis quasi doloribus architecto
          repellat aperiam alias.
        </Collapser>
      </div>

      <div>
        <Collapser initHeight={45}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore
          explicabo voluptates nam placeat excepturi error blanditiis adipisci
          voluptas! Corrupti qui dolorem id iste ex. Exercitationem blanditiis
          nostrum voluptatem praesentium consectetur quia doloremque esse
          laboriosam saepe, aut sapiente distinctio, reiciendis aliquam. Dolore
          consectetur quo incidunt velit sunt voluptas, consequatur earum labore
          repellendus placeat quae repellat omnis quam! Velit pariatur minima
          expedita at harum, fugit delectus quam recusandae omnis non dolorum
          esse id ipsa ad, atque ab adipisci necessitatibus sed soluta maiores
          nisi, doloribus ut dignissimos. Laudantium incidunt placeat mollitia
          nulla animi provident aperiam harum tempore totam a odit maiores in
          neque dolorum delectus iusto, sapiente vel error. Eveniet, ullam.
          Quasi quos quis accusantium quae facilis magnam corrupti officia modi,
          assumenda nesciunt.
        </Collapser>
      </div>
    </div>
  );
};
