import React, { useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import remarkGfm from 'remark-gfm';
import ym from '@appigram/react-yandex-metrika';
import { api } from 'services/api';
import { Nullable } from 'types/common';
import { Platform } from 'types/api/platform';
import { Loader } from 'components/Loader';
import { Button } from 'components/Button/Button';
import { BaseModal } from 'components/BaseModal';
import {
  ControlledSlider,
  ControlledSliderHandler,
  Slide,
} from 'components/Slider';
import styles from 'components/StaticMarkdownWrapper/StaticMarkdownWrapper.module.css';
import { PlatformSelect } from '../PlatformSelect';
import { Feedback } from '../Feedback';
import { SuccessFeedback } from '../SuccessFeedback';

export interface SnippetProps {
  bucketId: Nullable<string>;
  limit: number;
  onUpdate?: () => void;
}

export const Snippet: React.FC<SnippetProps> = ({
  bucketId,
  limit,
  onUpdate,
}) => {
  const { t } = useTranslation();
  const [isPlatformOpen, setIsPlatformOpen] = useState<boolean>(true);
  const [platform, setPlatform] = useState<Nullable<Platform>>(null);
  const slider = useRef<ControlledSliderHandler>(null);

  const { data: snippet, isLoading: isSnippetLoading } = useQuery(
    api.snippet.getPath(bucketId?.toString() || '', {
      ...(platform?.isAvailable && { platform: platform.key }),
    }),
    () =>
      api.snippet.getSnippet(bucketId?.toString() || '', {
        ...(platform?.isAvailable && { platform: platform.key }),
      }),
    {
      enabled: !!bucketId && (!!platform?.isAvailable || !isPlatformOpen),
    },
  );

  const handleSelectPlatform = (platform: Platform) => {
    setPlatform(platform);
    ym('reachGoal', 'choose_platform', { platform: platform.key });
    if (platform.isAvailable) setIsPlatformOpen(false);
    else slider.current?.next();
  };

  const handleCloseModal = () => {
    setIsPlatformOpen(false);
    if (!platform) ym('reachGoal', 'close_choose_platform');
    else ym('reachGoal', 'coming_soon_close', { platform: platform.name });
  };

  return (
    <div className="w-full h-full flex flex-col">
      {isSnippetLoading && (
        <div className="py-10 flex justify-center">
          <Loader color="blue" className="text-4xl" />
        </div>
      )}

      {snippet && (
        <ReactMarkdown remarkPlugins={[remarkGfm]} className={styles.markdown}>
          {snippet.snippet}
        </ReactMarkdown>
      )}

      <div className="w-full px-5 py-4 flex-1">
        <span className="text-[color:var(--gray)] whitespace-pre-line">
          {t('bucket.snippetPageUpdate', { n: limit })}
        </span>
      </div>

      <div className="w-full px-5 py-4">
        <Button color="blue" shape="circle" onClick={onUpdate}>
          {t('bucket.update')}
        </Button>
      </div>

      <BaseModal
        width={485}
        height={570}
        fullScreenOnMobile
        isOpen={isPlatformOpen}
        title={t('bucket.platformSelect.title').toString()}
        onClose={handleCloseModal}
      >
        <ControlledSlider ref={slider} show={1}>
          <Slide>
            <PlatformSelect
              bucketId={bucketId}
              onSelectPlatform={handleSelectPlatform}
            />
          </Slide>

          <Slide>
            {platform && (
              <Feedback
                platform={platform}
                onSubmitResolve={() => slider.current?.next()}
                onBack={() => slider.current?.prev()}
              />
            )}
          </Slide>

          <Slide>
            {platform && (
              <SuccessFeedback platform={platform} onDone={handleCloseModal} />
            )}
          </Slide>
        </ControlledSlider>
      </BaseModal>
    </div>
  );
};
