import React, { useMemo } from 'react';
import cx from 'classnames';
import styles from './Image.module.css';

export type TSize = string | number;
export type TFit = 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';

export interface ImageProps {
  src: string;
  alt?: string;
  width?: TSize;
  height?: TSize;
  fit?: TFit;
  onClick?: () => void;
}

export const Image: React.FC<ImageProps> = ({
  src,
  alt = null,
  width = null,
  height = null,
  fit = 'contain',
  onClick,
}) => {
  const classNames = useMemo(
    () => cx(styles.box, { [styles?.[fit]]: fit }),
    [fit],
  );

  const boxStyles = useMemo(
    () => ({ width: width ?? '100%', height: height ?? '100%' }),
    [width, height],
  );

  return (
    <div className={classNames} style={boxStyles} onClick={onClick}>
      <img src={src} alt={alt ?? ''} className={styles.image} />
    </div>
  );
};
