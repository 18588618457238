import {
  useState,
  useEffect,
  useMemo,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react';
import cx from 'classnames';
import { WithChildren } from 'types/common';
import { useExtraItems, useSliderChildren } from '../hooks';
import styles from './ControlledSlider.module.css';

export interface ControlledSliderProps extends WithChildren {
  show?: number;
  infiniteLoop?: boolean;
}

export interface ControlledSliderHandler {
  next: () => void;
  prev: () => void;
}

export const ControlledSlider = forwardRef<
  ControlledSliderHandler,
  ControlledSliderProps
>(({ children, show = 1, infiniteLoop = false }, ref) => {
  const { Slides, length } = useSliderChildren(children);
  const { extraNext, extraPrevious } = useExtraItems(Slides, length, show);

  const isRepeating = useMemo(
    () => infiniteLoop && length > show,
    [length, infiniteLoop, show],
  );

  const [currentIndex, setCurrentIndex] = useState(isRepeating ? show : 0);
  const [isTransitionEnabled, setIsTransitionEnabled] = useState(true);
  const [isAnimationInProgress, setIsAnimationInProgress] = useState(false);

  const contentStyles = useMemo(
    () => ({
      transform: `translateX(-${currentIndex * (100 / show)}%)`,
      ...(!isTransitionEnabled && { transition: 'none' }),
    }),
    [isTransitionEnabled, currentIndex, show],
  );

  const isCanPrev = useMemo(
    () => isRepeating || currentIndex > 0,
    [currentIndex, isRepeating],
  );

  const isCanNext = useMemo(
    () => isRepeating || currentIndex < length - show,
    [currentIndex, length, isRepeating, show],
  );

  const next = useCallback(() => {
    if (!isAnimationInProgress && isCanNext) {
      setCurrentIndex((prev) => prev + 1);
      setIsAnimationInProgress(true);
    }
  }, [isAnimationInProgress, isCanNext]);

  const prev = useCallback(() => {
    if (!isAnimationInProgress && isCanPrev) {
      setCurrentIndex((prev) => prev - 1);
      setIsAnimationInProgress(true);
    }
  }, [isAnimationInProgress, isCanPrev]);

  const handleTransitionEnd = useCallback(() => {
    if (isRepeating)
      if (currentIndex === 0) {
        setIsTransitionEnabled(false);
        setCurrentIndex(length);
      } else if (currentIndex === length + show) {
        setIsTransitionEnabled(false);
        setCurrentIndex(show);
      }

    setIsAnimationInProgress(false);
  }, [show, isRepeating, length, currentIndex]);

  useEffect(() => {
    if (isRepeating && (currentIndex === show || currentIndex === length))
      requestAnimationFrame(() => setIsTransitionEnabled(true));
  }, [currentIndex, isRepeating, length, show]);

  useEffect(() => {
    setCurrentIndex(isRepeating ? show : 0);
  }, [isRepeating, length, show]);

  useImperativeHandle(ref, () => ({ next, prev }), [next, prev]);

  return (
    <div className={styles.slider}>
      <div className={styles.content_wrapper}>
        <div
          className={cx(styles.content, styles[`show-${show}`])}
          style={contentStyles}
          onTransitionEnd={handleTransitionEnd}
        >
          {isRepeating && extraPrevious}
          {Slides}
          {isRepeating && extraNext}
        </div>
      </div>
    </div>
  );
});

ControlledSlider.displayName = 'ControlledSlider';
