import React, { useMemo } from 'react';
import cx from 'classnames';
import { WithChildren } from 'types/common';

export interface ChipProps extends WithChildren {
  className?: string;
  onClick?: () => void;
}

export const Chip: React.FC<ChipProps> = ({ children, className, onClick }) => {
  const classNames = useMemo(
    () =>
      cx(
        'rounded-full p-4 bg-[color:var(--gray-light)]',
        'cursor-pointer hover:bg-[color:var(--coconut-white)]',
        'transition-colors duration-300',
        className,
      ),
    [className],
  );

  return (
    <div className={classNames} onClick={onClick}>
      <div className="text-sm leading-snug">{children}</div>
    </div>
  );
};
