import { Tab } from '@headlessui/react';
import cx from 'classnames';
import { Buttons } from './modules/Buttons';
import { Collapsers } from './modules/Collapsers';
import { Icons } from './modules/Icons';
import { Checkboxes } from './modules/Checkboxes';
import { Modals } from './modules/Modals';
import { Imgaes } from './modules/Images';
import { Inputs } from './modules/Inputs';
import { Sliders } from './modules/Sliders';

const tabs = {
  Buttons: {
    id: 1,
    component: Buttons,
  },
  Collapsers: {
    id: 2,
    component: Collapsers,
  },
  Icons: {
    id: 3,
    component: Icons,
  },
  Checkboxes: {
    id: 4,
    component: Checkboxes,
  },
  Modals: {
    id: 5,
    component: Modals,
  },
  Images: {
    id: 6,
    component: Imgaes,
  },
  Inputs: {
    id: 7,
    component: Inputs,
  },
  Sliders: {
    id: 8,
    component: Sliders,
  },
};

export const UIKit = () => {
  return (
    <div className="w-full h-full px-6 py-2">
      <Tab.Group>
        <Tab.List className="flex flex-wrap gap-2 rounded-xl mb-2">
          {Object.keys(tabs).map((tab) => (
            <Tab
              key={tab}
              className={({ selected }) =>
                cx(
                  'rounded-lg py-2.5 px-6 text-md font-medium leading-5',
                  'bg-blue-100 hover:bg-blue-300 hover:text-blue-500 outline-none ring-0',
                  'transition-all',
                  selected
                    ? '!bg-blue-400 shadow !text-white'
                    : 'text-blue-300',
                )
              }
            >
              {tab}
            </Tab>
          ))}
        </Tab.List>

        <Tab.Panels className="h-[calc(100%-60px)]">
          {Object.values(tabs).map(({ id, component: Component }) => (
            <Tab.Panel key={id} className="w-full h-full px-2 py-1 rounded-lg">
              <Component />
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};
