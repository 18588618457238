import { ApiControllerBase } from '../base/api-controller-base';
import { BaseFetcher } from '../fetcher';

export class WriteController extends ApiControllerBase {
  constructor(client: BaseFetcher) {
    super(client, 'write');
  }

  public createLog(id: string, data: string): Promise<void> {
    return this.process(this.put('', { body: { bucket: id, data } }));
  }
}
