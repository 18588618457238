import { SnippetModel } from 'types/api/snippet';
import { ApiControllerBase } from '../base/api-controller-base';
import { BaseFetcher, FetcherQueryParams } from '../fetcher';
import { PlatformKey } from 'types/api/platform';
import { omit } from 'utils/common/omit';

export interface GetSnippetParams {
  platform?: PlatformKey;
}

export class SnippetController extends ApiControllerBase {
  constructor(client: BaseFetcher) {
    super(client, 'snippet');
  }

  public getSnippet(
    id: string,
    params?: GetSnippetParams & FetcherQueryParams,
  ): Promise<SnippetModel> {
    return this.process(
      this.get(id.toString(), {
        params: {
          ...(params?.platform && { framework: params?.platform }),
          ...(params && omit(params, 'platform')),
        },
      }),
    );
  }
}
