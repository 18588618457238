import React, { Fragment, ReactNode, useMemo, useRef } from 'react';
import { Transition } from '@headlessui/react';
import useOutsideClick from 'utils/hooks/use-outside-click';
import styles from './SlidingPanel.module.css';

export interface SlidingPanelProps {
  isOpen: boolean;
  panel: ReactNode;
  children: ReactNode;
  size?: string | number;
  clipContent?: boolean;
  onClose?: () => void;
}

export const SlidingPanel: React.FC<SlidingPanelProps> = ({
  isOpen,
  panel,
  children,
  size = '80%',
  clipContent = true,
  onClose,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);

  useOutsideClick(panelRef, () => onClose?.(), {
    mouse: 'mouseup',
    touch: 'touchend',
    excludes: [contentRef],
  });

  const panelStyles = useMemo(() => ({ width: size }), [size]);

  const contentStyles = useMemo(
    () => (isOpen && clipContent ? { paddingRight: size } : {}),
    [isOpen, size, clipContent],
  );

  return (
    <div className="relative w-full h-full flex items-stretch overflow-x-hidden">
      <div
        ref={contentRef}
        style={contentStyles}
        className="w-full h-full transition-[padding] duration-500"
      >
        {children}
      </div>

      <Transition
        show={isOpen}
        as={Fragment}
        enter="transition-all duration-500"
        enterFrom="opacity-0 translate-x-full"
        enterTo="opacity-100 translate-x-0"
        leave="transition-all duration-500"
        leaveFrom="opacity-100 transtale-x-0"
        leaveTo="opacity-0 translate-x-full"
      >
        <div className={styles.panel} ref={panelRef} style={panelStyles}>
          {panel}
        </div>
      </Transition>
    </div>
  );
};
