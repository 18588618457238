import { QueryClient, QueryClientProvider } from 'react-query';
import { YMInitializer } from '@appigram/react-yandex-metrika';
import { Route, Routes } from 'react-router-dom';
import { BaseLayout } from 'layouts/BaseLayout';
import { Home } from 'pages/Home';
import { Bucket } from 'pages/Bucket';
import { UIKit } from 'pages/UIKit';

const YM_ID = Number(process.env.REACT_APP_YM_ID ?? '93994268');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchIntervalInBackground: false,
    },
  },
});

function Application() {
  return (
    <QueryClientProvider client={queryClient}>
      {!!YM_ID && !Number.isNaN(YM_ID) && (
        <YMInitializer
          accounts={[YM_ID]}
          options={{
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true,
          }}
        />
      )}

      <Routes>
        <Route path="/" element={<BaseLayout />}>
          <Route index element={<Home />} />
          <Route path="/:id" element={<Bucket />} />
          <Route path="/ui-kit" element={<UIKit />} />
        </Route>
      </Routes>
    </QueryClientProvider>
  );
}

export default Application;
