import React, { forwardRef, useMemo } from 'react';
import { Transition } from '@headlessui/react';
import cx from 'classnames';
import { Nullable } from 'types/common';
import styles from './Input.module.css';

export interface InputProps {
  value?: Nullable<string>;
  error?: Nullable<string>;
  onChange?: (value: string) => void;
  onKeyDown?: (key: string) => void;
}

export type ExtraProps = Omit<
  React.ComponentPropsWithRef<'input'>,
  'value' | 'onChange' | 'onKeyDown'
>;

export const Input = forwardRef<HTMLInputElement, InputProps & ExtraProps>(
  ({ value, error, className, onChange, onKeyDown, ...rest }, ref) => {
    const classNames = useMemo(
      () => cx(styles.input, className, { [styles.error]: error }),
      [className, error],
    );

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
      onChange?.(event.target.value);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) =>
      onKeyDown?.(event.key);

    return (
      <div className={styles.wrapper}>
        <input
          ref={ref}
          value={value ?? ''}
          className={classNames}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          {...rest}
        />

        <Transition
          show={!!error && !!error?.length}
          enter="ease-out duration-200"
          enterFrom="opacity-0 h-0"
          enterTo="opacity-100 h-7"
          leave="ease-in duration-150"
          leaveFrom="opacity-100 h-7"
          leaveTo="opacity-0 h-0"
          className={styles.error_box}
        >
          <span className={styles.error_text}>{error}</span>
        </Transition>
      </div>
    );
  },
);

Input.displayName = 'Input';
