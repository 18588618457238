import { FeedbackResponse, CreateFeedbackParams } from 'types/api/';
import { ApiControllerBase } from '../base/api-controller-base';
import { BaseFetcher } from '../fetcher';

export class FeedbackController extends ApiControllerBase {
  constructor(client: BaseFetcher) {
    super(client, 'feedback');
  }

  public feedback({
    email,
    platform,
  }: CreateFeedbackParams): Promise<FeedbackResponse> {
    return this.process(
      this.post<FeedbackResponse>('', { body: { email, framework: platform } }),
    );
  }
}
