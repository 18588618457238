import React from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import ym from '@appigram/react-yandex-metrika';
import { api } from 'services/api';
import { Platform, CreateFeedbackParams } from 'types/api';
import { hasOwnProperty, isObject, isString } from 'utils/common/fields';
import { Button } from 'components/Button/Button';
import { Input } from 'components/Input';
import { PlatformChip } from '../../components/PlatformChip';

export interface FeedbackProps {
  platform: Platform;
  onSubmitResolve?: () => void;
  onBack?: () => void;
}

export const Feedback: React.FC<FeedbackProps> = ({
  platform,
  onSubmitResolve,
  onBack,
}) => {
  const { t } = useTranslation();
  const { handleSubmit, control } = useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: CreateFeedbackParams) => api.feedback.feedback(data),
    onSuccess: () => {
      ym('reachGoal', 'coming_soon_email_sent', { platform: platform.key });
      onSubmitResolve?.();
    },
  });

  const onSubmit = async (data: unknown) => {
    if (!isObject(data)) return;

    if (hasOwnProperty(data, 'email') && isString(data.email)) {
      mutate({ email: data.email, platform: platform.key });
    }
  };

  const handleEnter = (key: string) =>
    key === 'Enter' && handleSubmit(onSubmit)();

  return (
    <div className="w-full h-full flex flex-col justify-between">
      <div className="w-full flex justify-center mb-4">
        <PlatformChip name={platform.name} icon={platform.icon} />
      </div>

      <div className="w-full">
        <h3 className="text-center text-2xl leading-snug font-medium">
          {t('bucket.platformSelect.comingSoonTitle')}
        </h3>

        <p className="text-center text-lg leading-normal mb-5">
          {t('bucket.platformSelect.comingSoonDescription', {
            platform: platform.name,
          })}
        </p>

        <Controller
          name="email"
          control={control}
          rules={{
            required: t('app.fields.email.required').toString(),
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: t('app.fields.email.validError').toString(),
            },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              type="email"
              value={value}
              onChange={onChange}
              onKeyDown={handleEnter}
              placeholder={t('app.fields.email.placeholder').toString()}
              error={error?.message ?? null}
            />
          )}
        />
      </div>

      <div className="flex flex-col items-center gap-4 mt-4">
        <Button
          wide
          color="blue"
          shape="circle"
          size="lg"
          loading={isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          {t('bucket.platformSelect.informMe')}
        </Button>

        <Button color="blue" theme="plain" onClick={onBack}>
          {t('bucket.platformSelect.back')}
        </Button>
      </div>
    </div>
  );
};
