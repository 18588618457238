import fetch from 'isomorphic-fetch';
import { omit } from 'utils/common/omit';
import {
  FetcherUri,
  FetcherError,
  RequestMethods,
  FetcherParams,
  FetcherQueryParams,
} from '../types';

export abstract class BaseFetcher {
  protected static readonly AbsoluteURLPattern: RegExp = /^(?:[a-z+]+:)?\/\//i;
  private _baseUrl: string = '';

  protected constructor(
    _baseUrl: string,
    protected readonly baseHeaders?: Record<string, string>,
  ) {
    this.baseUrl = _baseUrl;
  }

  protected async process<T = any, D = any>(
    input: FetcherUri,
    init?: FetcherParams<D>,
  ): Promise<T> {
    const res = await fetch(this.createUri(input, init?.params), {
      headers: {
        ...this.baseHeaders,
        ...init?.headers,
      },
      ...(init && omit(init, 'body')),
      ...(init?.body && { body: JSON.stringify(init.body) }),
    });

    if (res.ok) {
      return res.json();
    }

    const error = new Error(res.statusText) as FetcherError;
    error.response = res;

    return Promise.reject(error);
  }

  protected checkIsUrlAbsolute(url: string): boolean {
    return BaseFetcher.AbsoluteURLPattern.test(url);
  }

  protected get baseUrl(): string {
    return this._baseUrl;
  }

  protected set baseUrl(value: string) {
    if (this.checkIsUrlAbsolute(value)) {
      this._baseUrl = value;
    } else {
      throw new RangeError('Base URL should be absolute url address');
    }
  }

  public createUri(uri: FetcherUri, params?: FetcherQueryParams): FetcherUri {
    const url = new URL(uri, this.baseUrl);

    for (const prop in params) {
      url.searchParams.append(prop, params[prop].toString());
    }

    return url;
  }

  public get<T>(input: FetcherUri, init?: FetcherParams): Promise<T> {
    return this.process(input, {
      ...init,
      method: RequestMethods.GET,
    });
  }

  public post<T, D>(input: FetcherUri, init?: FetcherParams<D>): Promise<T> {
    return this.process(input, {
      ...init,
      method: RequestMethods.POST,
    });
  }

  public put<T, D>(input: FetcherUri, init?: FetcherParams<D>): Promise<T> {
    return this.process(input, {
      ...init,
      method: RequestMethods.PUT,
    });
  }

  public patch<T, D>(input: FetcherUri, init?: FetcherParams<D>): Promise<T> {
    return this.process(input, {
      ...init,
      method: RequestMethods.PATCH,
    });
  }

  public delete<T, D>(input: FetcherUri, init?: FetcherParams<D>): Promise<T> {
    return this.process(input, {
      ...init,
      method: RequestMethods.DELETE,
    });
  }
}
