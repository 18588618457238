import { useState } from 'react';
import { Checkbox } from 'components/Checkbox';

export const Checkboxes = () => {
  const [x, setX] = useState(false);
  const [y, setY] = useState(false);

  return (
    <div className="w-full flex flex-col gap-4">
      <div className="w-full">
        <h3 className="text-lg font-medium text-blue-400">Reversed</h3>

        <div className="w-full mt-2 flex flex-col gap-2">
          <Checkbox label="Test checkbox" checked={x} onChange={setX} />
          <Checkbox
            reversed
            label="Test checkbox"
            checked={y}
            onChange={setY}
          />
        </div>
      </div>

      <div className="w-full">
        <h3 className="text-lg font-medium text-blue-400">Wide</h3>

        <div className="w-full mt-2 flex flex-col gap-2">
          <Checkbox wide label="Test checkbox" checked={x} onChange={setX} />
          <Checkbox
            wide
            reversed
            label="Test checkbox"
            checked={y}
            onChange={setY}
          />
        </div>
      </div>
    </div>
  );
};
