import { Children, ReactNode, useMemo } from 'react';

import { SlideT } from '../types';

export interface IUserSliderChildren {
  length: number;
  Slides: SlideT[];
}

export const useSliderChildren = (children: ReactNode): IUserSliderChildren => {
  const Slides = useMemo(
    () =>
      Children.toArray(children).filter(
        (slide: any) => slide?.type?.displayName === 'ControlledSlider:Slide',
      ) as SlideT[],
    [children],
  );

  const length = useMemo(() => Slides.length, [Slides]);

  return { length, Slides };
};
