import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LogModel } from 'types/api';
import { Nullable } from 'types/common';
import useDateFormat from 'utils/hooks/use-date-format';
import { Button } from 'components/Button/Button';
import { Collapser } from 'components/Collapser';
import { Icon } from 'components/Icon';

export interface LogModalProps {
  log: Nullable<LogModel>;
  onClose?: () => void;
}

export const LogModal: React.FC<LogModalProps> = ({ log, onClose }) => {
  const { t } = useTranslation();
  const { format } = useDateFormat('ru-RU');

  const formattedJsonString = useMemo(() => {
    try {
      return log?.data
        ? JSON.stringify(JSON.parse(JSON.parse(log.data)), null, 2)
        : log?.data;
    } catch (e) {
      return log?.data ?? null;
    }
  }, [log]);

  return (
    <div className="w-full h-full p-6">
      <div className="mb-2.5 py-2 flex justify-between items-center">
        <h3 className="text-lg md:text-2xl tracking-[0.33px]">
          {t('bucket.logModal.title')}
        </h3>
        <Button theme="plain" shape="square" size="xs" onClick={onClose}>
          <Icon name="close" className="text-xl" />
        </Button>
      </div>

      <div className="flex flex-col justify-start items-start mb-2.5 py-2 border-b-[1px] border-b-[color:var(--gray-light-alpha)] md:flex-row">
        <div className="min-w-[6rem] mr-2.5">
          <span className="text-base md:text-lg text-[color:var(--gray)] tracking-[0.33px]">
            {t('bucket.logModal.id')}
          </span>
        </div>

        <div className="w-full">
          <span className="text-base md:text-lg trancking-[0.33px]">
            {log?.uuid}
          </span>
        </div>
      </div>

      <div className="flex flex-col justify-start items-start mb-2.5 py-2 border-b-[1px] border-b-[color:var(--gray-light-alpha)] md:flex-row">
        <div className="min-w-[6rem] mr-2.5">
          <span className="text-base md:text-lg text-[color:var(--gray)] tracking-[0.33px]">
            {t('bucket.logModal.date')}
          </span>
        </div>

        <div className="w-full">
          <span className="text-base md:text-lg trancking-[0.33px]">
            {log?.timestamp ? format(log.timestamp) : null}
          </span>
        </div>
      </div>

      {formattedJsonString && (
        <div className="flex flex-col justify-start items-start mb-2.5 py-2 border-b-[1px] border-b-[color:var(--gray-light-alpha)] md:flex-row">
          <div className="min-w-[6rem] mr-2.5">
            <span className="text-base md:text-lg text-[color:var(--gray)] tracking-[0.33px]">
              {t('bucket.logModal.body')}
            </span>
          </div>

          <div className="w-full">
            <span className="text-lg trancking-[0.33px]">
              <Collapser initHeight={170}>
                <span className="text-sm md:text-lg tracking-[0.33px] whitespace-pre-wrap select-text">
                  {formattedJsonString}
                </span>
              </Collapser>
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
