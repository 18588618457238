import React from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from 'components/Header';

export interface BaseLayoutProps {}

export const BaseLayout: React.FC<BaseLayoutProps> = () => {
  return (
    <div className="w-full h-full flex flex-col">
      <Header />

      <main className="flex-1">
        <Outlet />
      </main>
    </div>
  );
};
