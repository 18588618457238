import { useMemo, ReactNode } from 'react';
import { SlideT } from '../types';

export interface IUseExtraItems {
  extraNext: ReactNode;
  extraPrevious: ReactNode;
}

export const useExtraItems = (
  slides: SlideT[],
  length: number,
  show: number,
): IUseExtraItems => {
  const extraPrevious = useMemo(() => {
    const output = [];

    for (let index = 0; index < show; index++) {
      output.push(slides[length - 1 - index]);
    }

    output.reverse();
    return output;
  }, [slides, show, length]);

  const extraNext = useMemo(() => {
    const output = [];

    for (let index = 0; index < show; index++) {
      output.push(slides[index]);
    }

    return output;
  }, [slides, show]);

  return {
    extraNext,
    extraPrevious,
  };
};
