import { Button } from 'components/Button/Button';
import { Icon } from 'components/Icon';
import React from 'react';

export interface BaseHeaderProps {
  title: string;
  onClose: () => void;
}

export const BaseHeader: React.FC<BaseHeaderProps> = ({ title, onClose }) => {
  return (
    <div className="flex justify-between items-center">
      <span className="text-lg font-medium font-medium">{title}</span>

      <Button theme="plain" shape="square" size="xs" onClick={onClose}>
        <Icon name="close" />
      </Button>
    </div>
  );
};
