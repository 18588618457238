import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'types/api/platform';
import { Icon } from 'components/Icon';
import { Button } from 'components/Button/Button';

export interface SuccessFeedbackProps {
  platform: Platform;
  onDone?: () => void;
}

export const SuccessFeedback: React.FC<SuccessFeedbackProps> = ({
  platform,
  onDone,
}) => {
  const { t } = useTranslation();

  return (
    <div className="w-full h-full flex flex-col justify-between">
      <div className="w-full flex justify-center pb-4 pt-[20%]">
        <div className="w-[5.3rem] h-[5.3rem] flex justify-center items-center bg-[color:var(--green)] rounded-full">
          <Icon
            name="checked"
            stroked
            className="text-6xl text-[var(--white)]"
          />
        </div>
      </div>

      <div className="w-full">
        <h3 className="text-center text-2xl font-medium leading-[1.3] tracking-[-0.03rem]">
          {t('bucket.platformSelect.successTitle')}
        </h3>

        <p className="text-center text-lg leading-normal">
          {t('bucket.platformSelect.successDescription', {
            platform: platform.name,
          })}
        </p>
      </div>

      <div className="w-full">
        <Button wide color="blue" shape="circle" size="lg" onClick={onDone}>
          {t('bucket.platformSelect.successDone')}
        </Button>
      </div>
    </div>
  );
};
