import { useState } from 'react';
import { Button } from 'components/Button/Button';
import { BaseModal } from 'components/BaseModal';

export const Modals = () => {
  const [isOpenModalOne, setIsOpenModalOne] = useState(false);
  const [isOpenModalTwo, setIsOpenModalTwo] = useState(false);

  return (
    <div className="w-full">
      <div className="flex gap-4">
        <Button
          color="blue"
          shape="rounded"
          onClick={() => setIsOpenModalOne(true)}
        >
          Open Modal One
        </Button>

        <Button
          color="blue"
          shape="rounded"
          onClick={() => setIsOpenModalTwo(true)}
        >
          Open Modal Two
        </Button>
      </div>

      <BaseModal
        title="Some modal title"
        isOpen={isOpenModalOne}
        onClose={() => setIsOpenModalOne(false)}
      >
        test
      </BaseModal>

      <BaseModal
        width="500px"
        height="50%"
        isOpen={isOpenModalTwo}
        onClose={() => setIsOpenModalTwo(false)}
      >
        test2
      </BaseModal>
    </div>
  );
};
