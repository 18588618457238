import { useEffect, useState } from 'react';
import { Image } from 'components/Image';

const conf = { width: 100, height: 100 };

const randInt = (min: number, max: number) => Math.random() * (max - min) + min;

export const Imgaes = () => {
  const [size, setSize] = useState(randInt(40, 400));

  useEffect(() => {
    const timer = setInterval(() => setSize(randInt(40, 400)), 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="w-full flex flex-col gap-4">
      <div className="w-full">
        <h3 className="text-lg font-medium text-blue-400">Fit</h3>

        <div className="flex gap-4 mt-2">
          <Image src="/images/wallpaper.jpeg" fit="fill" {...conf} />
          <Image src="/images/wallpaper.jpeg" fit="contain" {...conf} />
          <Image src="/images/wallpaper.jpeg" fit="cover" {...conf} />
          <Image src="/images/wallpaper.jpeg" fit="none" {...conf} />
          <Image src="/images/wallpaper.jpeg" fit="scale-down" {...conf} />
        </div>
      </div>

      <div className="w-full">
        <h3 className="text-lg font-medium text-blue-400">
          Size (width & height)
        </h3>

        <div className="flex gap-4 mt-2">
          <Image src="/images/wallpaper.jpeg" width={200} height={200} />

          <Image src="/images/wallpaper.jpeg" width={300} height={100} />
        </div>

        <div
          className="w-20 h-20 mt-4 transition-all duration-300"
          style={{ width: size, height: size }}
        >
          <Image src="/images/wallpaper.jpeg" fit="cover" />
        </div>
      </div>
    </div>
  );
};
