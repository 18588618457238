import React, { useMemo } from 'react';
import cx from 'classnames';
import { Button } from 'components/Button/Button';
import { Icon } from 'components/Icon';
import { TextTruncate } from 'components/TextTruncate';
import styles from './LogCard.module.css';
import useDateFormat from 'utils/hooks/use-date-format';

export interface LogCardProps {
  id: string;
  createdAt: Date | number;
  body: string;
  active?: boolean;
  onClick?: () => void;
}

export const LogCard: React.FC<LogCardProps> = ({
  id,
  createdAt,
  body,
  active = false,
  onClick,
}) => {
  const { format } = useDateFormat('ru-RU');

  const classNames = useMemo(
    () => cx('w-full py-2 pl-6 pr-2', styles.log, { [styles.active]: active }),
    [active],
  );

  return (
    <div className={classNames} onClick={onClick}>
      <div className="mb-1 flex justify-between items-center">
        <span className="text-xs text-[color:var(--gray)]">{id}</span>

        <div className="flex md:hidden">
          <Button theme="plain" shape="square" size="xs">
            <Icon name="arrow-angle-right" />
          </Button>
        </div>
      </div>

      <div className="flex flex-col justify-start items-start gap-2 md:flex-row">
        <div className="basis-1/4">
          <span className="text-base md:text-lg">{format(createdAt)}</span>
        </div>

        <div className="basis-3/4">
          <TextTruncate lineClamp={3}>
            <span className="text-sm md:text-lg break-all select-text">
              {body}
            </span>
          </TextTruncate>
        </div>

        <div className="hidden md:block">
          <Button theme="plain" shape="square" size="xs">
            <Icon name="arrow-angle-right" />
          </Button>
        </div>
      </div>
    </div>
  );
};
