import React from 'react';
import { Image } from 'components/Image';

export interface PlatfromChipProps {
  name: string;
  icon: string;
  onClick?: () => void;
}

export const PlatformChip: React.FC<PlatfromChipProps> = ({
  name,
  icon,
  onClick,
}) => {
  return (
    <div
      className="px-2.5 py-2 flex flex-col items-center cursor-pointer"
      onClick={onClick}
    >
      <Image width={80} height={80} src={icon} alt={name} />

      <div className="mt-1">
        <span className="text-lg leading-normal">{name}</span>
      </div>
    </div>
  );
};
