import { useCallback, useMemo } from 'react';

export type DateToFormatT = Date | number;

export default function useDateFormat(locale: string) {
  const formatter = useMemo(
    () =>
      new Intl.DateTimeFormat(locale, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        fractionalSecondDigits: 3,
      }),
    [locale],
  );

  const format = useCallback(
    (date: DateToFormatT): string => formatter.format(date),
    [formatter],
  );

  return { format };
}
