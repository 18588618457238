export type FetcherError = Error & { response: Response };

export type FetcherUri = URL | string;

export type FetcherQueryParams = Record<string, string | number | boolean>;

export type FetcherParams<D = any> = {
  body?: D;
  params?: FetcherQueryParams;
} & Omit<RequestInit, 'body'>;

export enum RequestMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}
