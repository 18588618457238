import React, { useMemo } from 'react';
import cx from 'classnames';
import { WithChildren } from 'types/common';
import styles from './Slide.module.css';

export interface SlideProps extends WithChildren {
  indented?: boolean;
  className?: string;
}

export const Slide: React.FC<SlideProps> = ({
  children,
  indented = false,
  className = null,
}) => {
  const classNames = useMemo(
    () => cx(styles.slide, className, { [styles.indented]: indented }),
    [indented, className],
  );

  return <div className={classNames}>{children}</div>;
};

Slide.displayName = 'ControlledSlider:Slide';
