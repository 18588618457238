import React from 'react';
import { Icon } from 'components/Icon';
import { Link } from 'react-router-dom';

export interface LogoProps {
  name: string;
  subname?: string;
}

export const Logo: React.FC<LogoProps> = ({ name, subname = null }) => {
  return (
    <Link to="/" className="flex justify-start items-center">
      <Icon name="logo" className="text-3xl" />

      <div className="ml-3 flex items-center text-base">
        <span className="font-semibold">{name}</span>

        {subname && (
          <>
            <div className="mx-3 w-px h-3 bg-[color:var(--gray)]" />
            <span className="font-light text-[var(--gray)]">{subname}</span>
          </>
        )}
      </div>
    </Link>
  );
};
