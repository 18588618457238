import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { Button } from 'components/Button/Button';
import { Icon } from 'components/Icon';
import styles from './Collapser.module.css';

export interface CollapserProps {
  initHeight: number;
  children: ReactNode;
}

export const Collapser: React.FC<CollapserProps> = ({
  initHeight,
  children,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [height, setHeight] = useState<number>(initHeight);

  const isSimple = useMemo(() => initHeight > height, [height, initHeight]);

  const iconClassNames = useMemo(
    () => cx(styles.icon, { [styles.opened]: isOpen }),
    [isOpen],
  );

  const wrapperStyles = useMemo(
    () => ({
      height: isOpen ? height : isSimple ? height : initHeight,
    }),
    [isOpen, height, isSimple, initHeight],
  );

  const toggleOpen = () => setIsOpen((prev) => !prev);

  const updateHeight = () => setHeight(contentRef.current?.scrollHeight || 0);

  useEffect(() => {
    updateHeight();
  }, []);

  return (
    <div className={styles.collapser}>
      <div className={styles.wrapper} style={wrapperStyles}>
        <div ref={contentRef} className={styles.content}>
          {children}
        </div>
      </div>

      {!isSimple && (
        <div className={styles.toggler}>
          <Button theme="plain" shape="square" size="xs" onClick={toggleOpen}>
            <Icon name="arrow-angle-right" className={iconClassNames} />
          </Button>
        </div>
      )}
    </div>
  );
};
