import { Button, ButtonProps } from 'components/Button/Button';

const CreateButtonProps = (props: Partial<ButtonProps>): ButtonProps => ({
  theme: 'default',
  color: 'blue',
  size: 'md',
  shape: 'rounded',
  disabled: false,
  loading: false,
  children: <>Button</>,
  ...props,
});

const btns = [
  {
    title: 'Themes',
    config: [
      CreateButtonProps({ theme: 'default' }),
      CreateButtonProps({ theme: 'outline' }),
      CreateButtonProps({ theme: 'plain' }),
    ],
  },
  {
    title: 'Colors',
    config: [
      CreateButtonProps({ color: 'dark' }),
      CreateButtonProps({ color: 'light' }),
      CreateButtonProps({ color: 'blue' }),
    ],
  },
  {
    title: 'Sizes',
    config: [
      CreateButtonProps({ size: 'lg' }),
      CreateButtonProps({ size: 'md' }),
      CreateButtonProps({ size: 'sm' }),
      CreateButtonProps({ size: 'xs' }),
    ],
  },
  {
    title: 'Shapes',
    config: [
      CreateButtonProps({ shape: 'square' }),
      CreateButtonProps({ shape: 'rectangle' }),
      CreateButtonProps({ shape: 'rounded' }),
      CreateButtonProps({ shape: 'circle' }),
    ],
  },
  {
    title: 'Dispabled',
    config: [
      CreateButtonProps({ shape: 'square', color: 'blue', disabled: true }),
      CreateButtonProps({
        shape: 'rectangle',
        color: 'dark',
        theme: 'outline',
        disabled: true,
      }),
      CreateButtonProps({ shape: 'rounded', color: 'light', disabled: true }),
      CreateButtonProps({
        shape: 'circle',
        color: 'blue',
        theme: 'outline',
        disabled: true,
      }),
      CreateButtonProps({
        shape: 'circle',
        color: 'blue',
        theme: 'plain',
        disabled: true,
      }),
    ],
  },
  {
    title: 'Loading',
    config: [
      CreateButtonProps({ shape: 'square', color: 'blue', loading: true }),
      CreateButtonProps({
        shape: 'rectangle',
        color: 'dark',
        theme: 'outline',
        loading: true,
      }),
      CreateButtonProps({ shape: 'rounded', color: 'light', loading: true }),
      CreateButtonProps({
        shape: 'circle',
        color: 'blue',
        theme: 'outline',
        loading: true,
      }),
      CreateButtonProps({
        shape: 'circle',
        color: 'blue',
        theme: 'plain',
        loading: true,
      }),
    ],
  },
  {
    title: 'Wide',
    config: [
      CreateButtonProps({ wide: true, size: 'lg', shape: 'circle' }),
      CreateButtonProps({ wide: true, size: 'lg', shape: 'rectangle' }),
      CreateButtonProps({ wide: true, size: 'lg' }),
    ],
  },
];

export const Buttons = () => {
  return (
    <div>
      {btns.map(({ title, config }) => (
        <div key={title} className="w-full mb-2 last:mb-0">
          <h3 className="text-lg font-medium text-blue-400">{title}</h3>

          <div className="w-full mt-1 flex gap-4 items-center">
            {config.map((values, idx) => (
              <Button key={idx} {...values} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
