import { useRef } from 'react';
import {
  ControlledSlider,
  ControlledSliderHandler,
  Slide,
} from 'components/Slider';
import { Button } from 'components/Button/Button';

export const Sliders = () => {
  const slider = useRef<ControlledSliderHandler>(null);

  return (
    <div className="w-full">
      <ControlledSlider ref={slider} show={1} infiniteLoop>
        <Slide indented>
          <div className="bg-blue-100 h-[400px] flex justify-center items-center">
            test1
          </div>
        </Slide>
        <Slide indented>
          <div className="bg-blue-100 h-[400px] flex justify-center items-center">
            test2
          </div>
        </Slide>
        <Slide indented>
          <div className="bg-blue-100 h-[400px] flex justify-center items-center">
            test3
          </div>
        </Slide>
        <Slide indented>
          <div className="bg-blue-100 h-[400px] flex justify-center items-center">
            test4
          </div>
        </Slide>
      </ControlledSlider>

      <div className="px-2 mt-4 flex gap-2">
        <Button color="blue" onClick={() => slider.current?.prev()}>
          prev
        </Button>

        <Button color="blue" onClick={() => slider.current?.next()}>
          next
        </Button>
      </div>
    </div>
  );
};
