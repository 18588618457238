import React, { Fragment, ReactNode, useMemo } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import cx from 'classnames';
import { Overlay } from './Overlay';
import { BaseHeader } from './BaseHeader';
import useMedia from 'utils/hooks/use-media';

const DEFAULT_MODAL_WIDTH = '40%';
const DEFAULT_MODAL_HEIGHT = '70%';

export type SizeT = string | number;

export interface BaseModalProps {
  isOpen: boolean;
  title?: string;
  width?: SizeT;
  height?: SizeT;
  children: ReactNode;
  className?: string;
  wrapperClassName?: string;
  overlayClassName?: string;
  fullScreenOnMobile?: boolean;
  onClose: () => void;
}

export const BaseModal: React.FC<BaseModalProps> = ({
  isOpen,
  title = null,
  width = DEFAULT_MODAL_WIDTH,
  height = DEFAULT_MODAL_HEIGHT,
  children,
  className,
  overlayClassName,
  wrapperClassName,
  fullScreenOnMobile = false,
  onClose,
}) => {
  const isMobile = useMedia('(max-width: 680px)');

  const styles = useMemo(
    () => ({
      width: fullScreenOnMobile && isMobile ? '100%' : width,
      height: fullScreenOnMobile && isMobile ? '100%' : height,
    }),
    [width, height, fullScreenOnMobile, isMobile],
  );

  const classNames = useMemo(
    () =>
      cx(
        'flex flex-col relative bg-[color:var(--white)] rounded-2xl p-6',
        className,
      ),
    [className],
  );

  const wrapperClassNames = useMemo(
    () =>
      cx(
        'fixed inset-0 w-full flex justify-center items-center md:px-4 md:py-4',
        wrapperClassName,
      ),
    [wrapperClassName],
  );

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog onClose={onClose} className="relative z-50">
        <Overlay className={overlayClassName} />

        <div className={wrapperClassNames}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-200"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className={classNames} style={styles}>
              {title && (
                <div className="w-full mb-2.5">
                  <BaseHeader title={title} onClose={onClose} />
                </div>
              )}

              <div className="w-full flex-1">{children}</div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
