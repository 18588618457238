import React, { useMemo } from 'react';
import cx from 'classnames';
import { WithChildren } from 'types/common';
import styles from './TextTruncate.module.css';

export interface TextTruncateProps extends WithChildren {
  lineClamp: number;
  className?: string;
}

export const TextTruncate: React.FC<TextTruncateProps> = ({
  lineClamp,
  className,
  children,
}) => {
  const classNames = useMemo(
    () => cx('text-base', styles.text, className),
    [className],
  );

  return (
    <span className={classNames} style={{ WebkitLineClamp: lineClamp }}>
      {children}
    </span>
  );
};
