import { BucketController } from 'libs/apis/controllers/bucket.controller';
import { FeedbackController } from 'libs/apis/controllers/feedback.controller';
import { ReadController } from 'libs/apis/controllers/read.controller';
import { SnippetController } from 'libs/apis/controllers/snippet.controller';
import { WriteController } from 'libs/apis/controllers/write.controller';
import { BaseFetcher, Fetcher } from 'libs/apis/fetcher';

export class Api {
  public readonly write: WriteController;
  public readonly read: ReadController;
  public readonly bucket: BucketController;
  public readonly snippet: SnippetController;
  public readonly feedback: FeedbackController;

  constructor(client: BaseFetcher) {
    this.write = new WriteController(client);
    this.read = new ReadController(client);
    this.bucket = new BucketController(client);
    this.snippet = new SnippetController(client);
    this.feedback = new FeedbackController(client);
  }
}

export const baseUrl =
  process.env.REACT_APP_BASE_URL || 'http://127.0.0.1:3000';
export const fetcher = new Fetcher(baseUrl);
export const api = new Api(fetcher);
