import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ym from '@appigram/react-yandex-metrika';
import { api } from 'services/api';
import { Button } from 'components/Button/Button';
import { Chip } from 'components/Chip';
import { Image } from 'components/Image';

export const Home = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const steps = useMemo(
    () => [
      t('home.steps.first'),
      t('home.steps.second'),
      t('home.steps.third'),
      t('home.steps.fourth'),
    ],
    [t],
  );

  const handleCreateBucket = async () => {
    setIsLoading(true);
    const bucket = await api.bucket.create();
    ym('reachGoal', 'create_log_button');
    setIsLoading(false);
    navigate(`/${bucket.slug}`);
  };

  return (
    <div className="w-full h-full flex flex-col gap-10 md:flex-row">
      <div className="w-full pl-6 pt-10 md:w-1/2">
        <h1 className="text-2xl leading-snug tracking-wide lg:pr-[30%]">
          {t('home.title')}
        </h1>

        <div className="py-6 flex flex-col gap-1 items-start">
          {steps.map((step, i) => (
            <Chip key={i}>{step}</Chip>
          ))}
        </div>

        <Button
          color="blue"
          shape="circle"
          size="lg"
          loading={isLoading}
          onClick={handleCreateBucket}
        >
          {t('home.createLog')}
        </Button>
      </div>

      <div className="w-full pl-6 md:pl-0 md:pt-10 md:w-1/2">
        <div className="overflow-hidden">
          <Image
            src="/images/home-banner.png"
            alt="banner"
            fit="contain"
            width={530}
            height={385}
          />
        </div>
      </div>
    </div>
  );
};
