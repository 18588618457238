import React from 'react';
import { useTranslation } from 'react-i18next';
import { LogModel } from 'types/api';
import { Nullable } from 'types/common';
import { Button } from 'components/Button/Button';
import { LogCard } from 'components/LogCard';

export interface LogsListProps {
  limit: number;
  logs: LogModel[];
  activeLog?: Nullable<LogModel>;
  isCanLoadMore: boolean;
  isLoadingMore: boolean;
  onLogClick?: (log: LogModel) => void;
  onLoadMore?: () => void;
  onUpdate?: () => void;
}

export const LogsList: React.FC<LogsListProps> = ({
  limit,
  logs,
  activeLog,
  isCanLoadMore,
  isLoadingMore,
  onLogClick,
  onLoadMore,
  onUpdate,
}) => {
  const { t } = useTranslation();

  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full flex-1">
        {logs.map((log) => (
          <LogCard
            key={log.uuid}
            id={log.uuid}
            body={log.data}
            createdAt={log.timestamp}
            active={activeLog?.uuid === log.uuid}
            onClick={() => onLogClick?.(log)}
          />
        ))}
      </div>

      <div className="w-full p-4 flex items-start justify-start gap-4 flex-col md:flex-row md:items-center">
        <Button color="blue" shape="circle" onClick={onUpdate}>
          {t('bucket.update')}
        </Button>

        <Button
          theme="outline"
          shape="circle"
          loading={isLoadingMore}
          disabled={!isCanLoadMore || isLoadingMore}
          onClick={onLoadMore}
        >
          {t('bucket.loadMore')}
        </Button>

        <span className="text-[color:var(--gray)]">
          {t('bucket.loadMoreDescription', { n: limit })}
        </span>
      </div>
    </div>
  );
};
