import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { Logo } from 'components/Logo';
import styles from './Header.module.css';

export interface HeaderProps {}

export const Header: React.FC<HeaderProps> = () => {
  const { t } = useTranslation();

  const classNames = useMemo(
    () =>
      cx(
        'w-full px-6 py-4 flex flex-col justify-between gap-3 sm:flex-row sm:items-center',
        styles.header,
      ),
    [],
  );

  return (
    <header className={classNames}>
      <Logo name="King Bird Studio" subname="Fastlog" />

      <div>
        <span className="text-sm leading-snug text-[var(--gray-darker-alpha)] mr-1">
          {t('app.header.support')}
        </span>

        <a
          href={`mailto:${t('app.header.supportEmail')}`}
          className="text-sm leading-snug underline text-[var(--blue-light)]"
        >
          {t('app.header.supportEmail')}
        </a>
      </div>
    </header>
  );
};
