import { useEffect, useState } from 'react';
import { Icon } from 'components/Icon';

const getRandomColor = (): string =>
  `#${Math.floor(Math.random() * 16777215).toString(16)}`;

export const Icons = () => {
  const [color, setColor] = useState(getRandomColor());

  useEffect(() => {
    const timer = setInterval(() => setColor(getRandomColor()), 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="w-full flex flex-col gap-4">
      <div>
        <h3 className="text-lg font-medium text-blue-400">Stroked</h3>

        <div className="w-full mt-1 flex gap-4">
          <Icon name="close" stroked />

          <Icon name="arrow-angle-right" stroked />
        </div>
      </div>

      <div>
        <h3 className="text-lg font-medium text-blue-400">Filled</h3>

        <div className="w-full mt-1 flex gap-4">
          <Icon name="close" filled />

          <Icon name="arrow-angle-right" filled />
        </div>
      </div>

      <div>
        <h3 className="text-lg font-medium text-blue-400">Colors</h3>

        <div className="w-full mt-1 flex gap-4">
          <span className="text-red-400">
            <Icon name="close" />
          </span>

          <span className="text-blue-500">
            <Icon name="close" />
          </span>

          <span className="text-gray-500">
            <Icon name="close" />
          </span>

          <span className="transition-all duration-500" style={{ color }}>
            <Icon name="close" />
          </span>
        </div>
      </div>
    </div>
  );
};
