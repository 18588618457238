import { BucketModel, BucketResponse } from 'types/api';
import { ApiControllerBase } from '../base/api-controller-base';
import { BaseFetcher } from '../fetcher';

export class BucketController extends ApiControllerBase {
  constructor(client: BaseFetcher) {
    super(client, 'create');
  }

  public create(): Promise<BucketModel> {
    return this.process(this.post<BucketResponse>('')).then(
      ({ bucket }) => bucket,
    );
  }
}
