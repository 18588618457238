import { Button } from 'components/Button/Button';
import { Input } from 'components/Input';
import { useState } from 'react';

export const Inputs = () => {
  const [value, setValue] = useState<string>();
  const [error, setError] = useState<string | null>(null);

  return (
    <div className="w-full flex flex-col items-start gap-4">
      <Input
        type="number"
        placeholder="Placeholder"
        value={value}
        onChange={setValue}
      />

      <Input value="some value" error={error} />

      <Button size="xs" onClick={() => setError(error ? null : 'Some Error')}>
        Error
      </Button>
    </div>
  );
};
