import React, { useMemo } from 'react';
import cx from 'classnames';
import styles from './Loader.module.css';

export type LoaderColor = 'inherit' | 'light' | 'dark' | 'blue';

export interface LoaderProps {
  color?: LoaderColor;
  className?: string;
}

export const Loader: React.FC<LoaderProps> = ({
  color = 'inherit',
  className,
}) => {
  const classNames = useMemo(
    () => cx(styles.loader, styles?.[color], className),
    [color, className],
  );

  return <div className={classNames} />;
};
