import React, { useId, useMemo } from 'react';
import cx from 'classnames';
import { CheckboxChecker } from './Checkbox.checker';
import styles from './Checkbox.module.css';

export interface CheckboxProps {
  label: string;
  checked: boolean;
  wide?: boolean;
  reversed?: boolean;
  onChange: (checked: boolean) => void;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  label,
  checked,
  wide = false,
  reversed = false,
  onChange,
}) => {
  const id = useId();

  const classNames = useMemo(
    () =>
      cx(styles.checkbox, { [styles.wide]: wide, [styles.reversed]: reversed }),
    [wide, reversed],
  );

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) =>
    onChange(event.target.checked);

  return (
    <div className={classNames}>
      <input
        id={id}
        type="checkbox"
        checked={checked}
        className={styles.input}
        onChange={handleCheck}
      />

      <label htmlFor={id} className={styles.label}>
        <CheckboxChecker checked={checked} />

        <span className={styles.label_text}>{label}</span>
      </label>
    </div>
  );
};
